.calendar-container {
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.custom-calendar {
  width: 100% !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
}

.blue {
  background-color: #4a90e2 !important;
  color: white !important;
  border-radius: 4px;
}

.green {
  background-color: #2ecc71 !important;
  color: white !important;
  border-radius: 4px;
}

.red {
  background-color: #ffffff !important;  
  color: #e74c3c !important;
  border-radius: 4px;
}

.selection-modes {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px;
}

.mode-btn {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s ease;
}

.mode-btn:hover {
  background-color: #e9ecef;
}

.mode-btn.active {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.mode-btn.active:hover {
  background-color: #0056b3;
}

.weekend {
  background-color: #f5f5f5 !important;
  color: #999 !important;
  cursor: not-allowed !important;
  opacity: 0.7;
}

.weekend:hover {
  background-color: #f5f5f5 !important;
}

.calendar-title {
  font-size: 1.2rem;
  color: #666;
  margin: 0.5rem 0;
  font-weight: 500;
  text-align: center;
}

.calendar-wrapper {
  position: relative;
}

.calendar-wrapper.processing {
  opacity: 0.7;
  pointer-events: none;
}

.calendar-wrapper.processing::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  cursor: wait;
}

@media (max-width: 768px) {
  .calendar-container {
    padding: 0.5rem;
  }
  
  .custom-calendar {
    font-size: 14px;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .custom-calendar {
    font-size: 12px;
    padding: 0.25rem;
  }
}

