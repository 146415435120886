.info-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .info-container p {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .info-container p .info-label {
    font-weight: bold;
    color: #333;
  }
  
  .info-container p .info-value {
    font-weight: normal;
    color: #333;
  }

  .info-container p .info-link {
    color: blue;
    text-decoration: underline;
  }

  .info-value-centered {
    text-align: center;
    font-size: 20px;
    color: green;
  }